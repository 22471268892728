<template>
  <vp-base-layout
    page-title="검수등급 입력"
    page-default-back-link="/">
    <p>휴대폰의 상태를 확인하고 해당하는 항목을 모두 체크해주세요.</p>

    <div>
      <ion-accordion-group>
        <ion-accordion value="first">
          <ion-item
            slot="header"
            color="light">
            <ion-label>1. A 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in aGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="second">
          <ion-item
            slot="header"
            color="light">
            <ion-label>2. A- 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in amGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="third">
          <ion-item
            slot="header"
            color="light">
            <ion-label>3. B+ 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in bpGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="fourth">
          <ion-item
            slot="header"
            color="light">
            <ion-label>4. B 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in bGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="fifth">
          <ion-item
            slot="header"
            color="light">
            <ion-label>5. 통단가 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in tGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="sixth">
          <ion-item
            slot="header"
            color="light">
            <ion-label>6. LCD 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in lGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="seventh">
          <ion-item
            slot="header"
            color="light">
            <ion-label>7. 페폰 등급</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-item 
              v-for="(item, index) in rGradeList"
              :key="index"> 
              <ion-label>
                {{ item.text }}
              </ion-label>
              <ion-checkbox
                slot="end"
                @ion-change="handleCheckbox(item, $event)"
                :value="item.value" />
            </ion-item>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </div>
    <div>
      <div class="l-button">
        <ion-button
          expand="full"
          color="light"
          disabled>
          등급: {{ gradeName }}
        </ion-button>
      </div>
    </div>

    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="handleInspectGrade">
      검수등급 입력
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonItem, IonLabel,
  IonButton, IonList, IonCheckbox, toastController
} from '@ionic/vue'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useIonRouter } from '@ionic/vue'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const ionRouter = useIonRouter()

const fixed = ref(true)
const checkedNames = ref([])
const checkedValues = ref([])
const gradeName = ref('')

const aGradeList = ref([
  {
    text: '전체깨끗',
    value: 'A'
  }
])
const amGradeList = ref([
  {
    text: '액정미세흡집',
    value: 'A-'
  },
  {
    text: '후면미세흠집',
    value: 'A-'
  },
  {
    text: '몸체미세흠집',
    value: 'A-'
  },
  {
    text: '카메라미세흠집',
    value: 'A-'
  }
])
const bpGradeList = ref([
  {
    text: '후면찍힘',
    value: 'B+'
  },
  {
    text: '몸체찍힘',
    value: 'B+'
  },
  {
    text: '카메라흠집',
    value: 'B+'
  }
])
const bGradeList = ref([
  {
    text: '후면찍힘심함',
    value: 'B'
  },
  {
    text: '몸체찍힘심함',
    value: 'B'
  },
  {
    text: '카메라찍힘심함',
    value: 'B'
  }
])  
const tGradeList = ref([
  {
    text: '액정파손',
    value: '통단가'
  },
  {
    text: '기능불량',
    value: '통단가'
  }
])
const lGradeList = ref([
  {
    text: '터치불량',
    value: 'LCD'
  },
  {
    text: '흑멍',
    value: 'LCD'
  },
  {
    text: '노란멍',
    value: 'LCD'
  },
  {
    text: '픽셀',
    value: 'LCD'
  },
  {
    text: '검수불가',
    value: 'LCD'
  }
])
const rGradeList = ref([
  {
    text: '폐휴대폰',
    value: '폐폰'
  }
])

//checkbox 클릭
const handleCheckbox = (obj, event) => {

  if(event.detail.checked){
    checkedNames.value.push(obj.text)
    checkedValues.value.push(obj.value)
  } else {
    const index = checkedNames.value.findIndex(v=> v ===  obj.text)
    if(index > -1){
      checkedNames.value.splice(index, 1)
      checkedValues.value.splice(index, 1)
    }
  }

  const lv7 = checkedValues.value.includes('폐폰')
  const lv6 = checkedValues.value.includes('LCD')
  const lv5 = checkedValues.value.includes('통단가')
  const lv4 = checkedValues.value.includes('B')
  const lv3 = checkedValues.value.includes('B+')
  const lv2 = checkedValues.value.includes('A-')
  const lv1 = checkedValues.value.includes('A')

  if(lv7){
    gradeName.value = '폐폰'
    return false
  }
  if(lv6){
    gradeName.value = 'LCD'
    return false
  }
  if(lv5){
    gradeName.value = '통단가'
    return false
  }
  if(lv4){
    gradeName.value = 'B'
    return false
  }
  if(lv3){
    gradeName.value = 'B+'
    return false
  }
  if(lv2){
    gradeName.value = 'A-'
    return false
  }
  if(lv1){
    gradeName.value = 'A'
    return false
  }
  gradeName.value = ''
}

const handleCheck = async() => {
  const toast = await toastController.create({
    message: '검수 등급에 체크해주세요.',
    buttons: ['확인']
  })
  await toast.present()
}

//검수등급 입력
const handleInspectGrade = () => {
  if(checkedNames.value.length === 0){
    handleCheck()
    return
  }
  store.dispatch('Receipt/setGrade1', gradeName.value)
  store.dispatch('Receipt/setGrade1Detail', checkedNames.value)
  ionRouter.push({name: 'enterAmount'})
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//--------------------------------------------------------------------------------------------------------------------- 
</script>